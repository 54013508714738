.icon_overlay {
  position: absolute;
  width: 25%;
  max-width: 250px;
  border-radius: 2px;
}

@media only screen and (max-width: 768px) {
  .icon_overlay.icon_overlay * {
    font-size: 10px !important;
  }
}

.icon_overlay * {
  font-size: 15px !important;
}
