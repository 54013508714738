.abilities > h2 {
  margin-bottom: 0px !important;
}

.abilities .paragraph,
.abilities .paragraph > span {
  font-size: 20px;
}

.abilities .paragraph > div:first-child {
  padding-right: 35px;
}

.abilities_counter_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.abilities_counter_wrapper > span {
  cursor: pointer;
  font-size: 20px;
  color: #06294b;
}

.abilities_counter_wrapper > span:hover {
  color: #ff2345;
}

.abilities_icon_wrapper {
  display: flex;
  flex-direction: column;
}

.abilities_icon_wrapper > span {
  cursor: pointer;
  border-radius: 20px;
}

.abilities_icon_wrapper > span:not(:last-child) {
  margin-bottom: 5px;
}

.abilities_text_wrapper {
  flex: 1 1;
  margin-bottom: 30px;
  padding: 10px 0px 0px 25px;
}

.abilities_text_wrapper .paragraph > div:last-child {
  margin-right: 15px;
}

.abilities_text_wrapper font {
  filter: drop-shadow(1px 1px 1px black);
}

.abilities_wrapper {
  display: flex;
  flex: 1;
}

.description > div:last-child {
  flex: 1;
  margin-bottom: 40px;
}

.description .paragraph,
.description .paragraph > span {
  font-size: 25px;
}

.description .paragraph {
  padding-right: 25px;
}

.stats > div {
  display: flex;
  flex: 1;
}

.stats img {
  height: 30px;
  width: auto;
  margin-right: 10px;
}

.stats span {
  font-size: 17px;
}

.stats_info {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  height: 150px;
}

.stats_info > span {
  display: flex;
  justify-content: center;
  width: max-content;
  min-width: 24%;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .stats_info img {
    width: 20px;
  }
}

.stats_wrapper > div:last-child,
.abilities_wrapper > div:last-child {
  height: 80%;
}

.wrapper {
  height: 260px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff80;
}

.wrapper:not(:last-child):after {
  content: "";
  width: 98%;
  height: 4px;
  background: #0f1111;
}

.wrapper div[class*="ant-slider-handle"] {
  border: solid 2px #06294b !important;
}

.wrapper div[class*="ant-slider-track"] {
  background-color: #ff2345 !important;
}

.wrapper h2 {
  color: black !important;
  font-weight: unset !important;
}

.wrapper div,
.wrapper span:not([class*="anticon"]) {
  color: #464646 !important;
}
