.champion_info {
  flex: 1;
  min-width: 0;
}

.champion_info li,
.champion_info li button {
  background: lightgray !important;
}

.champion_info li[class*="slick-active"],
.champion_info li[class*="slick-active"] button {
  background: #2d2d2d !important;
}

.col {
  display: flex;
  flex-direction: column;
  max-width: 50% !important;
  box-shadow: 0px 0px 5px 5px #090b0b;
  opacity: 0;
}

.col:global.animate__fadeInLeftBig {
  width: 100% !important;
  max-width: 100% !important;
}

.col:global.animate__fadeInLeftBig:not(:last-child) {
  margin-bottom: 20px;
}

.col:global.animate__fadeInLeftBig :global .ant-carousel {
  height: 100%;
}

.col:global.animate__fadeInLeftBig :global .slick-slider {
  max-height: 100%;
  overflow: hidden;
}

.col:global.animate__fadeInLeftBig :global .champion-info__stats-info img {
  width: 20px;
}

.landscape {
  position: relative;
  gap: 20px;
  flex-direction: row;
}

.landscape .champion_info {
  padding-left: 20px;
}

.landscape .champion_banner {
  position: absolute;
  left: 423px;
  height: 100%;
  width: 30px;
  display: flex;
  flex-direction: column;
}

.landscape .champion_banner img {
  width: 70%;
  height: auto;
}

.landscape .champion_banner span {
  width: 70%;
  height: auto;
  writing-mode: vertical-rl;
  text-orientation: upright;
  margin: 0;
  margin-top: 10px;
  max-height: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.portrait .champion_info {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 1s ease-out;
}

.portrait .splashart {
  width: 100%;
  object-fit: cover !important;
}

.portrait .splashart_wrapper {
  display: contents;
}

.shuffle {
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 20;
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 60px solid #fcfcfc;
  border-right: 60px solid transparent;
  cursor: pointer;
}

.shuffle > img {
  height: 20px;
  width: 20px;
  position: absolute;
  top: -50px;
  left: 5px;
}

.splashart {
  object-fit: contain;
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  object-position: top;
  height: 100%;
}

.splashart_name {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: #ffffffcc;
  font-size: 40px !important;
}

.splashart_wrapper {
  height: 100%;
  width: auto;
  position: relative;
}
.splashart_wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: inset 0px 0px 20px 5px;
}

.summoner_body {
  flex: 1;
  background-color: white;
}

.summoner_body :global .champion-info {
  height: 30%;
  min-height: 400px;
}

.summoner_body :global .champion-info__stats-info {
  height: 100%;
}

.summoner_body :global .champion-info__abilities-icon-wrapper {
  gap: 20px;
}

.summoner_header {
  height: 250px;
  width: 100%;
  display: flex;
  background-color: #f0f0f0;
  position: relative;
}

.portrait:global.animate__fadeInLeftBig
  :local
  .summoner_header:hover
  .splashart_name {
  opacity: 0;
}
.portrait:global.animate__fadeInLeftBig
  :local
  .summoner_header:hover
  .champion_info {
  opacity: 1;
}
