.icon {
  height: 31px;
  width: 31px;
  position: absolute;
  z-index: 3;
  background: #07294a;
  background-size: cover;
}

.icon_overlay {
  position: relative;
  min-width: 100%;
}

.icon_overlay * {
  min-height: 100% !important;
}

.error {
  background: white;
  color: #fe2345;
}

.wrapper :global .ant-input-lg {
  padding-left: 15px !important;
}
