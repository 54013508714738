.header {
  width: 100%;
  height: 40px;
  box-shadow: 0px 2px 10px 0px #0e1111;
  position: relative;
  z-index: 10;
  margin: 10px 0;
}

.logo_wrapper {
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content;
  margin: auto;
}

.logo_wrapper svg {
  height: 75%;
  width: auto;
}

.menu {
  position: absolute;
  top: 10px;
  right: 10px;
}

.menu svg {
  height: 20px;
  width: 20px;
  color: lightgray;
}
