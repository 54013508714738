.background {
  width: auto;
  max-width: 95vw;
  max-height: 95vh;
  margin: auto;
}

.background_tilt {
  position: relative;
  margin: auto;
  display: grid;
  width: max-content;
  height: auto;
}

.background_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow {
  position: absolute;
  box-shadow: inset 0px 10px 50px 50px #0e1111;
}
