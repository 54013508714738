.button_wrapper button {
  height: 100%;
  width: 100%;
}

.blue_wrapper button {
  background: #06294b;
  border-color: #06294b;
  width: 100%;
}

.blue_wrapper button:hover,
.blue_wrapper button:focus,
.blue_wrapper button:active,
.lightblue_button:hover,
.lightblue_button:focus,
.lightblue_button:active {
  background: #06294b;
  border-color: #06294b;
}

.red_wrapper button {
  background: #ff2345;
  border-color: #ff2345;
}

.red_wrapper button:hover,
.red_wrapper button:focus,
.red_wrapper button:active {
  background: #ff2345;
  border-color: #ff2345;
}

.lightblue_button {
  background: #0e538c !important;
  border-color: #0e538c !important;
}

.red_button {
  background: #ff2345 !important;
  border-color: #ff2345 !important;
}

.locked_in {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-image: linear-gradient(45deg, #e5b48f80, #06294b80);
  animation: rotate 1s infinite linear;
  z-index: 11;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
