.icon {
  height: auto;
  width: 5%;
  position: absolute;
  cursor: pointer;
  transition: top 1s ease-out, left 1s ease-out;
  background-color: #0e538c;
  border-radius: 200px;
  background-size: contain;
}

.icon:hover,
.highlight_wrapper {
  filter: saturate(1.5) contrast(1.5);
}

.icon[section="Top"] {
  top: 17%;
  left: 22%;
  animation-delay: 1s;
}

.icon[section="Mid"] {
  top: 47%;
  left: 48%;
  animation-delay: 1.5s;
}
.icon_fill[section="Mid"] {
  top: 33% !important;
  left: 36% !important;
}

.icon[section="Jungle"] {
  top: 67%;
  left: 50%;
  animation-delay: 2s;
}
.icon_fill[section="Jungle"] {
  top: 47% !important;
  left: 48% !important;
}

.icon[section="Bot"] {
  top: 75%;
  left: 73%;
  animation-delay: 2.5s;
}
.icon_fill[section="Bot"] {
  top: 62% !important;
  left: 61% !important;
}

.icon[section="Support"] {
  top: 80%;
  left: 78%;
  animation-delay: 3s;
}
.icon_fill[section="Support"] {
  top: 75% !important;
  left: 73% !important;
}

.icon_fill {
  animation-delay: 0s !important;
}
