.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.icon_wrapper {
  height: 100px;
  width: 100px;
}

.h1 {
  color: #fe2345;
  margin: 0;
}

.h2 {
  color: white;
  margin: 0;
}

.h_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
