.spinner_wrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0e1111;
  position: absolute;
  z-index: 100;
  pointer-events: none;
}
.spinner_border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(6, 41, 75);
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255, 0.1) 33%,
    rgb(255, 255, 255, 1) 100%
  );
  animation: spin 0.8s linear 0s infinite;
}

.spinner_core {
  width: 100%;
  height: 100%;
  background-color: #0e1111;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
