.banner_wrapper{
    background-color: #0e1111;
    color: white;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

.banner_wrapper img{
    height: 70%;    
}

.banner_wrapper span{
    margin-left: 20px;    
}