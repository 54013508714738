.drawerOption{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drawerOption > * {
    margin: 0px!important;
}

.drawerChecked{
    background-color: #FF2345!important;
}