.confirm {
  font-size: 26px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 12vw;
  min-width: 150px;
  filter: saturate(1.5);
  cursor: pointer;
}

.confirm_wrapper {
  position: absolute;
  bottom: 60px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}
